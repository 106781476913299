<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >

        <b-col
          sm="10"
          class="px-xl-2 mx-auto"
        >
          <!-- Brand logo-->
          <b-link class="d-flex justify-content-start mb-5">
            <vuexy-logo />

            <h2 class="brand-text text-primary ml-1">
              SPay
            </h2>
          </b-link>
          <!-- /Brand logo-->
          <b-card-title class="mb-1 text-center">
            {{ $t('Đăng ký') }}
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            {{ $t('Đăng ký để sử dụng dịch vụ') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-row>
                <b-col>
                  <!-- username -->
                  <b-form-group
                    :label="$t('Tên đăng nhập*')"
                    label-for="register-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Tên đăng nhập')"
                      vid="username"
                      rules="required|alpha-dash|min:6|max:12"
                    >
                      <b-form-input
                        id="register-username"
                        v-model="username"
                        name="register-username"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <!-- name -->
                  <b-form-group
                    :label="$t('Tên merchant*')"
                    label-for="register-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Tên merchant')"
                      vid="name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-name"
                        v-model="name"
                        name="register-name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="register-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="email"
                    >
                      <b-form-input
                        id="register-email"
                        v-model="userEmail"
                        name="register-email"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <!-- phone -->
                  <b-form-group
                    label="Số điện thoại"
                    label-for="register-phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Số điện thoại')"
                      vid="phone"
                      rules="phone-number"
                    >
                      <b-form-input
                        id="register-phone"
                        v-model="userPhone"
                        name="register-phone"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <!-- password -->
                  <b-form-group
                    label-for="register-password"
                    :label="$t('Mật khẩu')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Mật khẩu')"
                      vid="password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-password"
                          v-model="passwordValue"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="register-password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label-for="register-password-confirm"
                    :label="$t('Nhập lại mật khẩu')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Nhập lại mật khẩu')"
                      rules="required|confirmed:password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-password-confirm"
                          v-model="passwordCon"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldType"
                          class="form-control-merge"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('Xác nhận để đồng ý với') }}
                  <b-link>Điều khoản dịch vụ</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-col
                cols="4"
                class="text-center ml-auto mr-auto"
              >
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || !status || isSubmitting"
                >
                  {{ $t('Đăng ký') }}
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import {
  required,
  email,
  password,
  alphaDash,
  max,
  min,
  numeric,
  phoneNumber,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isSubmitting: false,
      status: '',
      username: '',
      name: '',
      userEmail: null,
      userPhone: null,
      passwordValue: '',
      passwordCon: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      password,
      alphaDash,
      min,
      max,
      numeric,
      phoneNumber,
      errors: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.isSubmitting = true
          let body = {
            username: this.username,
            password: this.passwordValue,
            name: this.name,
          }
          if (this.userEmail && this.userEmail !== '') {
            body = {
              ...body,
              email: this.userEmail,
            }
          }
          if (this.userPhone && this.userPhone !== '') {
            body = {
              ...body,
              phone: this.userPhone,
            }
          }
          useJwt.register(body)
            .then(() => {
              this.success()
              this.clearForm()
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.errors)
            }).finally(() => {
              this.isSubmitting = false
            })
        }
      })
    },
    success() {
      this.$swal({
        title: this.$t('Đăng ký tài khoản thành công!'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        confirmButtonText: this.$t('Tới trang đăng nhập'),
      }).then(result => {
        if (result.value) {
          this.$router.push('/login')
        }
      })
    },
    clearForm() {
      this.username = ''
      this.email = null
      this.passwordValue = ''
      this.passwordCon = ''
      this.name = ''
      this.userPhone = null
      this.$refs.registerForm.reset()
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
